import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid, { gridClasses } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import { formatFullDate } from 'shared/helpers/helpers';
import type { ControlCodeResponse } from 'shared/lib/types';
import { ControlCodeFrequencyTranslation } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectUserCanEditCompanySettings } from 'shared/state/slices/userSlice';

import { useGetControlCodesByCompanyQuery } from 'shared/api/rtkq/controlcodes';

import ControlCodeMenu from './ControlCodeMenu';

function ControlCodesGrid() {
  const canEditCompanySettings = useSelector(selectUserCanEditCompanySettings);
  const company = useSelector(selectCompany);
  const { currentData: controlCodes } = useGetControlCodesByCompanyQuery(
    company.trace_id,
  );

  function getLastUpdatedContent(controlCode: ControlCodeResponse) {
    const { user } = controlCode;
    const { first_name: firstName = '', last_name: lastName = '' } = user;

    return (
      <Box display="flex" flexDirection="row" gap={1.5}>
        <Avatar
          sx={{ width: 32, height: 32, alignSelf: 'center' }}
        >{`${firstName[0]}${lastName[0]}`}</Avatar>
        <Box>
          <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
          {!!controlCode.updated_at && (
            <Typography color="rgba(0, 0, 0, 0.56)" variant="body1">
              {formatFullDate(controlCode.updated_at)}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      sx={{
        borderRadius: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        [`& .${gridClasses.item}`]: {
          p: 3,
          flex: 1,
          flexBasis: '240px',
        },
        [`& .${gridClasses.container}:nth-of-type(even)`]: {
          backgroundColor: (theme) => theme.palette.grey[100],
        },
        [`& .${gridClasses.item}:nth-of-type(3)`]: {
          flex: 1,
          flexBasis: '410px',
        },
      }}
      container
    >
      <Grid
        flexWrap="nowrap"
        gap={5}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        container
      >
        <Grid item>
          <Typography variant="body2">Control code</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">Frequency</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">Description</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">Last updated</Typography>
        </Grid>
        <Grid width="50px" item />
      </Grid>
      {!!controlCodes?.length &&
        controlCodes.map((controlCode) => (
          <Grid
            key={controlCode.trace_id}
            flexWrap="nowrap"
            gap={5}
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            container
          >
            <Grid item>
              <Typography variant="body1">
                {controlCode.control_code}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {ControlCodeFrequencyTranslation[controlCode.control_frequency]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{controlCode.description}</Typography>
            </Grid>
            <Grid item>{getLastUpdatedContent(controlCode)}</Grid>
            <Grid textAlign="right" width="50px" item>
              {canEditCompanySettings && (
                <ControlCodeMenu controlCode={controlCode} />
              )}
            </Grid>
          </Grid>
        ))}
      {!controlCodes?.length && (
        <Grid container>
          <Grid display="flex" height="50px" item>
            <Typography alignSelf="center" variant="body1">
              No controls
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ControlCodesGrid;
