import { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SavingsIcon from '@mui/icons-material/SavingsOutlined';
import Upload from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query';

import Button from 'shared/ui/button/Button';
import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import ContractBudgetUploadWizard from 'accruals/wizards/contract-budget-upload-wizard/ContractBudgetUploadWizard';
import ContractAssumptionsWizard from 'accruals/wizards/contract-params-wizard/ContractAssumptionsWizard';
import ContractVersionWizard from 'accruals/wizards/contract-version-wizard/ContractVersionWizard';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import HiddenForReadOnlyPermission from 'shared/lib/read-only-permission/HiddenForReadOnlyPermission';

import { useGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import { useGetContractBudgetSnapshotsByContractQuery } from 'shared/api/rtkq/contractbudgetsnapshots';

import CroExpenseSummaryGrid from './grids/CroExpenseSummaryGrid';

function CroExpenseSummarySection() {
  const {
    contractContainer,
    contractContainerTraceId,
    contractContainerPeriodMenuItemTraceId,
    contractVersionTraceId,
    isClosedPeriodVersion,
  } = useContractAndPeriodWithVersions();

  const [newContractWizardOpen, setNewContractWizardOpen] = useState(false);
  const [contractBudgetWizardOpen, setContractBudgetWizardOpen] =
    useState(false);
  const [openContractAssumptionsWizard, setOpenContractAssumptionsWizard] =
    useState<boolean>(false);
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  const { currentData: assumptionGroups } =
    useGetContractAssumptionGroupsByContractVersionQuery(
      (!isClosedPeriodVersion && contractVersionTraceId) || skipToken,
    );
  const { currentData: budgetSnapshots } =
    useGetContractBudgetSnapshotsByContractQuery(
      (!isClosedPeriodVersion && contractVersionTraceId) || skipToken,
    );
  const hasCurrentContract = contractVersionTraceId !== undefined;
  const hasBudget = !!budgetSnapshots?.length;
  const hasAssumptions = !!assumptionGroups?.length;

  // this is always 5 rows high, but if we make it exactly 186 (which is what the math wants), it adds a scrollbar, so we add 1px to remove that
  const gridSx = useMemo(() => ({ height: 187, width: '100%' }), []);
  const userDisplayOptions = useMemo(
    () => ({
      currencyViewMode,
    }),
    [currencyViewMode],
  );

  return (
    <DashboardCard
      title="CRO Expense Summary"
      titleAction={
        (hasCurrentContract && hasBudget) || isClosedPeriodVersion ? (
          <CurrencyToggleGroup
            setValue={setCurrencyViewMode}
            testId="ClinicalExpenseSummaryToggle"
            value={currencyViewMode}
          />
        ) : undefined
      }
    >
      <Stack alignItems="center" display="flex" flexDirection="column">
        {hasCurrentContract || isClosedPeriodVersion ? (
          hasBudget || isClosedPeriodVersion ? (
            <CroExpenseSummaryGrid
              parentMenuItem={contractContainerPeriodMenuItemTraceId}
              sx={gridSx}
              userDisplayOptions={userDisplayOptions}
            />
          ) : (
            <>
              <SavingsIcon sx={{ color: 'grey.700', mt: 2.5 }} />
              <Typography sx={{ my: 2 }} variant="body1">
                Data for the contract will appear after you add your contract
                assumptions and budget. Please add the assumptions first.
              </Typography>
              <HiddenForReadOnlyPermission>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    disabled={hasAssumptions}
                    startIcon={<AddIcon />}
                    testId="add_contract_assumptions"
                    variant="contained"
                    onClick={() => setOpenContractAssumptionsWizard(true)}
                  >
                    Add Contract Assumptions
                  </Button>
                  <Button
                    disabled={!hasAssumptions}
                    startIcon={<Upload />}
                    testId="upload_budget"
                    variant="contained"
                    onClick={() => setContractBudgetWizardOpen(true)}
                  >
                    Upload Budget
                  </Button>
                </Box>
              </HiddenForReadOnlyPermission>
            </>
          )
        ) : (
          <>
            <SavingsIcon sx={{ color: 'grey.700', mt: 2.5 }} />
            <Typography sx={{ mt: 2.5 }} variant="body1">
              Add a version of the contract to get started.
            </Typography>
            <HiddenForReadOnlyPermission>
              <Button
                size="small"
                startIcon={<AddIcon />}
                sx={{ mt: 2 }}
                testId="new_version"
                variant="contained"
                onClick={() => setNewContractWizardOpen(true)}
              >
                New Version
              </Button>
            </HiddenForReadOnlyPermission>
          </>
        )}
      </Stack>
      {!!contractContainerTraceId && newContractWizardOpen && (
        <ContractVersionWizard
          contractContainerId={contractContainerTraceId}
          onClose={() => setNewContractWizardOpen(false)}
        />
      )}
      {contractBudgetWizardOpen ? (
        <ContractBudgetUploadWizard
          onClose={() => setContractBudgetWizardOpen(false)}
        />
      ) : null}
      {openContractAssumptionsWizard && (
        <ContractAssumptionsWizard
          contractVersionTraceId={contractVersionTraceId}
          vendorType={contractContainer?.vendor_type}
          onClose={() => setOpenContractAssumptionsWizard(false)}
        />
      )}
    </DashboardCard>
  );
}

export default CroExpenseSummarySection;
