import { useMemo } from 'react';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import useHasPermission from 'shared/lib/permissions/useHasPermission';

import GlAccountCellRenderer from '../grids/GlAccountCellRenderer';

export default function useGlAccountColumnDefs(
  isOpenPeriod: boolean,
): CondorColDef[] {
  const hasRequiredPermisionsAndIsOpenPeriod = useHasPermission({
    permissions: ['canEditCompanyLevelTrialInfo'],
    isOpenPeriod,
  });

  return useMemo(
    () => [
      { headerName: 'G/L account number', field: 'accountNumber', flex: 1 },
      { headerName: 'Account type', field: 'accountType', flex: 1 },
      { headerName: 'Description', field: 'description', flex: 1 },
      { headerName: 'Trial', field: 'trial', flex: 1 },
      {
        headerName: '',
        field: 'tools',
        width: 50,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        ...(hasRequiredPermisionsAndIsOpenPeriod && {
          cellRenderer: GlAccountCellRenderer,
        }),
      },
    ],
    [hasRequiredPermisionsAndIsOpenPeriod],
  );
}
