import type { MouseEvent } from 'react';
import { useState } from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';

import AddEditGlAccountDialog from 'shared/components/gl-accounts/AddEditGlAccountDialog';
import DeleteGlAccountDialog from 'shared/components/gl-accounts/DeleteGlAccountDialog';

import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetContractsByGlAccountQuery } from 'shared/api/rtkq/contracts';
import { useDeleteGlAccountMutation } from 'shared/api/rtkq/glaccounts';

export default function GlAccountCellRenderer(props: ICellRendererParams) {
  const { data } = props;
  const { accountNumber, traceId } = data;

  const company = useSelector(selectCompany);

  const { currentData: contracts } = useGetContractsByGlAccountQuery(
    traceId ?? skipToken,
  );
  const [deleteGlAccount] = useDeleteGlAccountMutation();

  const [menuAnchorEl, setMenuAnchorEl] = useState<SVGSVGElement | null>(null);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isEditGlAccountModalOpen, setIsEditGlAccountModalOpen] =
    useState(false);

  function onClickMore(event: MouseEvent<SVGSVGElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  function onDelete() {
    if (traceId !== null) {
      void deleteGlAccount(traceId);
    }
  }

  function onCloseMenu() {
    setMenuAnchorEl(null);
  }

  return (
    <>
      <MoreVertOutlined
        fontSize="small"
        sx={{ cursor: 'pointer' }}
        onClick={onClickMore}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={menuAnchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onCloseMenu}
      >
        <MenuItem
          onClick={() => {
            setIsEditGlAccountModalOpen(true);
            onCloseMenu();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsDeleteConfirmationModalOpen(true);
            onCloseMenu();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <DeleteGlAccountDialog
        accountNumber={accountNumber}
        contracts={contracts}
        isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onDelete={onDelete}
      />
      {isEditGlAccountModalOpen && (
        <AddEditGlAccountDialog
          companyTraceId={company.trace_id}
          editAccountTraceId={traceId}
          onClose={() => setIsEditGlAccountModalOpen(false)}
        />
      )}
    </>
  );
}
