import type { ReactElement } from 'react';

import Box from '@mui/material/Box';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';

import AddSiteLabDropdown from '../buttons/AddSiteLabDropdown';
import ManageVisitsAndProceduresDropdown from '../buttons/ManageVisitsAndProceduresDropdown';

function SiteLabHeader(): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        mb: 2,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <ManageVisitsAndProceduresDropdown />
      <AddSiteLabDropdown />
    </Box>
  );
}

export default withPeriodSpecificGenericWrapper(SiteLabHeader);
