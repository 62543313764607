import type { ICellRendererParams } from '@ag-grid-community/core';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

import IconButton from 'shared/ui/icon-button/IconButton';

import type { TrialFilesGridRow } from 'shared/lib/types';

import { useDeleteContractFileMutation } from 'shared/api/rtkq/contractfiles';

function AgGridContractFilesDeleteCellRenderer(params: ICellRendererParams) {
  const { data } = params;
  const { trace_id } = data as TrialFilesGridRow;
  const [deleteContractFile, { isLoading }] = useDeleteContractFileMutation();

  const handleDeleteContractFile = () => void deleteContractFile(trace_id);

  return (
    <IconButton
      disabled={isLoading}
      size="small"
      onClick={handleDeleteContractFile}
    >
      <DeleteIcon />
    </IconButton>
  );
}

export default AgGridContractFilesDeleteCellRenderer;
