import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';

import type { GridApi } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { selectUserCanEditCompanyUsersAndPermissions } from 'shared/state/slices/userSlice';

import UserAccessHeader from './components/UserAccessHeader';
import UserAccessGrid from './grids/UserAccessGrid';

function UserAccess() {
  const [showActiveUsers, setShowActiveUsers] = useState(false);
  const [userAccessGridApi, setUserAccessGridApi] = useState<GridApi | null>(
    null,
  );

  function handleFilterTextboxChanged(event: ChangeEvent<HTMLInputElement>) {
    userAccessGridApi?.setGridOption('quickFilterText', event.target.value);
  }
  const canEditCompanyUsersAndPermissions = useSelector(
    selectUserCanEditCompanyUsersAndPermissions,
  );

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <UserAccessHeader
          canEditCompanyUsersAndPermissions={canEditCompanyUsersAndPermissions}
          handleFilterTextboxChanged={handleFilterTextboxChanged}
          setShowActiveUsers={setShowActiveUsers}
          showActiveUsers={showActiveUsers}
        />
        <UserAccessGrid
          canEditCompanyUsersAndPermissions={canEditCompanyUsersAndPermissions}
          overlayNoRowsTemplate="No Data."
          setUserAccessGridApi={setUserAccessGridApi}
          showActiveUsers={showActiveUsers}
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default UserAccess;
