import { useSelector } from 'react-redux';

import type { UserPermissions } from 'shared/lib/types';
import { selectCurrentUserPermissions } from 'shared/state/slices/userSlice';

type Props = {
  isOpenPeriod?: boolean;
  permissions: Array<keyof UserPermissions>;
  separator?: 'AND' | 'OR';
};

export default function useHasPermission(props: Props) {
  const { permissions, isOpenPeriod, separator = 'AND' } = props;
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);

  const hasPermission =
    separator === 'AND'
      ? permissions.every(
          (permission: string) =>
            currentUserPermissions[permission as keyof UserPermissions],
        )
      : permissions.some(
          (permission: string) =>
            currentUserPermissions[permission as keyof UserPermissions],
        );

  return isOpenPeriod === undefined
    ? hasPermission
    : hasPermission && isOpenPeriod;
}
