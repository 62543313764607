import { type ReactElement, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import NavigationTabs from 'shared/ui/tabs/NavigationTabs';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import { getSiteLabCostMatrixTab } from 'routes';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import EditSite from './components/EditSite';
import SiteLabHeader from './components/SiteLabHeader';
import LabGrid from './grids/LabGrid';
import SiteGrid from './grids/SiteGrid';

function SiteLabCostMatrixPage(): ReactElement {
  const { activeTab } = useParams();
  const sx = useMemo(() => ({ height: '100%' }), []);
  const tabContent = activeTab
    ? {
        sites: <SiteGrid overlayNoRowsTemplate="No sites added." sx={sx} />,
        labs: <LabGrid overlayNoRowsTemplate="No labs added." sx={sx} />,
      }[activeTab]
    : undefined;

  return (
    <PageContentLoadingContainer
      tabs={
        <NavigationTabs
          activeTab={activeTab}
          keyToUrl={getSiteLabCostMatrixTab}
          tabs={[
            { key: 'sites', label: 'Sites' },
            {
              key: 'labs',
              label: 'Labs',
            },
          ]}
        />
      }
    >
      <FlexGridContainer>
        <SiteLabHeader />
        <EditSite />
        {tabContent}
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(SiteLabCostMatrixPage);
